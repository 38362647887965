
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions } from 'vuex'

  import ToolKit from '@/components/toolkit'

  import { Query as IQuery } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { extract } from '@/graphql/forms'

@Component({
  components: { ToolKit },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class Playground extends Vue {
  @Prop({ type: [String, Number] }) uid?: number;

  @Prop({ type: String, required: true }) model!: string;
  @Prop({ type: String, required: true }) slug!: string;

  @Prop({ type: Object }) value?: any;

  fetchData!: (payload: {
    query: IQuery
    filter?: formFilter
    force?: boolean
    limit?: number
    params?: any
    offset?: number
    distinct?: Array<string>
  }) => Promise<any>

  lazyValue = null

  async created () {
    const { uid } = this
    if (!uid) return

    const { model, value } = this
    const { Model } = extract({ name: 'fetch', model })

    this.internalValue = value?.constructor === Model
      ? value
      : await this.fetchData({ query: { name: 'fetch', model, params: { id: uid } } })
  }

  get internalValue () {
    return this.lazyValue
  }

  set internalValue (value) {
    this.lazyValue = value
  }

  get toolkit () {
    const { internalValue: value } = this
    if (!value) return undefined

    const { model, slug } = this
    return { model, slug, value }
  }
  }
